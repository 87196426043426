/*--------------------------------------------------------------
# Form styles
--------------------------------------------------------------*/

$brand-grey: #F5F5F5;
$brand-mid-grey: #E6E6E6;
$brand-dark-grey: #A5AAAA;
$brand-dark: #19233C;
$brand-orange: #FF7D45;
$brand-aqua: #4BE6CB;

$brand-transition: .2s ease-in-out;

$font-18: 1.125rem;

.field-error {
	margin-top: 0.313rem;
	color: #CD2026;
	display: block;
}

.calculator-form,
.filter-form,
.lead-gen-form {
    // shared properties
    input[type=radio],
    input[type=checkbox] {
        // hide default input
        cursor: pointer;
        height: 0;
        opacity: 0;
        position: absolute;
        width: 0;
    }

    label {
        font-weight: 300;
    }

    &__radio {
        gap: 0.625rem;

        input[type=radio] {
            + label {
                background-color: #fff;
                border: solid 0.125rem $brand-dark-grey;
                border-radius: 0.75rem;
                cursor: pointer;
                font-size: $font-18;
                font-weight: 600;
                padding: 11px 3.25rem;
                transition: background-color $brand-transition, border-color $brand-transition;
            }
    
            &:hover {
                + label {
                    background-color: $brand-grey;
                }
            }
    
            // change appearance when input is checked
            &:checked {
                + label {
                    background-color: $brand-dark;
                    border-color: $brand-dark;
                }
    
                &:not(:hover) {
                    + label {
                        color: #fff;
                    }
                }
    
                &:hover {
                    + label {
                        background-color: $brand-grey;
                    }
                }
            }
        }
    }

    input[type=checkbox] {
        + label {
            cursor: pointer;
            padding-left: 1.875rem;
    
            &::before,
            &::after {
                height: 1.375rem;
                position: absolute;
                translate: 0 4px;
                width: 1.375rem;
            }
    
            &::before {
                background-color: #fff;
                border: 0.125rem solid $brand-dark;
                content: '';
                left: 0;
                top: 50%;
                transition: background-color $brand-transition, box-shadow $brand-transition;
                translate: 0 -50%;
            }
    
            // hover state
            &:hover {
                &::before {
                    background-color: $brand-mid-grey;
                }
            }
        }

        // turn label into custom style
        + label {
            &::after {
                border: solid #fff;
                border-width: 0 0.125rem 0.125rem 0;
                content: none;
                height: 0.625rem;
                left: 9px;
                top: 50%;
                transform: rotate(45deg);
                translate: 0 calc(-50% - 1px);
                width: 0.313rem;
            }
        }
    
        // change appearance when input is checked
        &:checked {
            + label {
                &::before {
                    background-color: $brand-dark;
                    border-color: $brand-dark;
                }
    
                &::after {
                    content: '';
                }
            }
        }
    }

    &__no-mortgage {
        background-color: $brand-grey;
        border-radius: 0.75rem;
        display: none;
        margin-top: 1.875rem;
        padding: 1.5rem;

        h2 {
            font-size: 1.25rem;
            font-weight: 700;
            line-height: 150%;
        }

        ul {
            margin-top: 26px;

            li {
                color: $brand-orange;

                + li {
                    margin-top: 0.75rem;
                }
            }
        }
    }

    .form-group {
        width: 100%;
    }

    .form-group-elements {
        display: block;

        input[type=number],
		input[type=text],
		input[type=password] {
            + strong {
                left: 1.063rem;
                position: absolute;
                top: 0.75rem;
            }
        }

		input[type=text],
		input[type=password] {
			padding-left: 40px;
		}

        + .form-group-elements {
            margin-top: 1.5rem;
        }

        span {
            font-size: $font-18;
            font-weight: 300;
            white-space: nowrap;
        }
    }

    .form-group-years + .form-group-elements {
        margin-top: 0.625em;
    }

    .form-group-years {
        input[type=number] {
            padding-left: 1.25rem;

            + strong {
                position: absolute;
                right: 1.063rem;
                top: 0.75rem;
            }
        }
    }

    legend,
    label {
        font-size: $font-18;
        font-weight: 300;
        line-height: 150%;
        margin-bottom: 0.313rem;

        span {
            color: $brand-orange;
        }
    }

    legend {
        @media screen and (min-width: 992px) {
            margin-bottom: 1rem;
        }
    }

    input[type=number],
	input[type=text],
	select,
	input[type=date],
	input[type=password],
    input[type=email] {
        border: solid 1px $brand-dark-grey;
        border-radius: 0.625rem;
        font-size: $font-18;
        font-weight: 700;
        padding: 0.625rem 1rem;
        width: 100%;

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
		
		&.error {
			border: solid 1px #CD2026;
		}
    }

    input[type=number]:not(#telNum):not(#borrowYears):not(#max_ltv):not(#rate):not(#ltv_factor):not(#brokerFee) {
        padding-left: 40px;
    }

    input[type=range] {
        -webkit-appearance: none;
        border-radius: 0.313rem;
        height: 0.625rem;
        margin-left: 0.625rem;
        margin-right: 0.625rem;
        translate: 0 6px;
        width: 100%;

        &::-webkit-slider-thumb {
            -webkit-appearance: none;
            appearance: none;
            background: #fff;
            border: solid 1px $brand-dark;
            border-radius: 50%; 
            cursor: pointer;
            height: 1.563rem;
            width: 1.563rem;
        }

        &::-moz-range-thumb {
            border-radius: 50%;
            background: #fff;
            border: solid 1px $brand-dark;
            cursor: pointer;
            height: 1.563rem;
            width: 1.563rem;
        }
    }
}

.calculator-form:has(input[type=radio]#no:checked ) {
    .calculator-form__no-mortgage {
        display: block;
    }

    .form-group:not(fieldset),
    .btn-link {
        display: none !important;
    }
}

.calculator-form {
    .form-group {
        + .form-group,
        + p {
            margin-bottom: 2rem;
            margin-top: 2rem;

			@media screen and (max-width: 991px) {
				margin-bottom: 1.5rem;
				margin-top: 1.5rem;
			}
        }

        + p {
            @media screen and (min-width: 992px) {
                margin-bottom: 0.625rem;
                margin-top: 0.625rem;
            }
        }
    }
}

.lead-gen-form {
    margin-top: 1.5rem;
    
    &__fields {
        @media screen and (max-width: 991px) {
            margin-top: 1.25rem;

            .form-group + .form-group {
                margin-top: 1.25rem;
            }
        }

        @media screen and (min-width: 992px) {
            gap: 1.25rem;
            margin-top: 2.25rem;
        }
    }

    &__info {
        background-color: $brand-grey;
        border-radius: 0.75rem;
        margin-top: 1.5rem;
        padding: 2.125rem 2.5rem;

        @media screen and (min-width: 992px) {
            margin-top: 2.625rem;
        }

        a {
            color: $brand-dark;
            text-decoration-color: currentColor;
            text-underline-offset: 0.125rem;

            &:hover {
                text-decoration-thickness: 0.125rem;
            }
        }

        p:has(strong) {
            margin-bottom: 0.5rem;

            strong {
                font-size: 1.25rem;
            }
        }
    }

    &__submit {
        margin-top: 1.5rem;
    }
}

.red-ast {
	color: $brand-orange;
}

.filter-form {
	display: flex;
	align-items: center;
	justify-content: space-between;

	.form-group {
		width: 40%;
		margin-right: 20px;
	}

	.btn-link {
		width: 20%;
		align-self: flex-end;
	}
}